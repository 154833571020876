/*

@Author: Themezinho
@URL: http://www.themezinho.net
 
This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.


	// Table of contents //

		01. FONTS
		02. BODY
		03. LOCOMOTIVE
		04. HTML ELEMENTS
		05. LINKS
		06. FORM ELEMENTS
		07. SPACING
		08. ACCORDION
		09. HAMBURGER
		10. PRELOADER
		11. PAGE TRANSITION
		12. SEARCH BOX
		13. SIDE WIDGET
		14. NAVBAR 
		15. SLIDER
		16. PLAY NOW
		17. PAGE HEADER
		18. CONTENT SECTION
		19. VIDEO BG
		20. CUSTOM LINK
		21. CUSTOM BUTTON
		22. CIRCLE BUTTON
		23. IMAGE BOX
		24. SIDE ICON LIST
		25. IMAGE BOX CAROUSEL
		26. SIDE CONTENT
		27. SIDE IMAGE
		28. SIDE LIST
		29. SIDE ARTS
		30. COUNTER BOX
		31. IMAGE CONTENT BOX
		32. TESTIMONIALS BOX
		33. COLLECTION BOX
		34. EXHIBITION BOX
		35. TEXT BOX
		36. ART SLIDER
		37. HORIZONTAL SCROLL
		38. IMAGE ICON BOX
		39. CTA BOX
		40. NOTE BOX
		41. RECENT NEWS
		42. NEWSLETTER BOX
		43. CONTACT BOX
		44. CONTACT FORM
		45. GOOGLE MAPS
		46. BLOG POST
		47. SIDEBAR
		48. FOOTER

	

*/
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600&family=DM+Sans:wght@400;500;700&display=swap");
@import "@fortawesome/fontawesome-free/css/all.min.css";

* {
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  overflow-y: auto;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* LOCOMOTIVE */
/* .no-transform {
  transform: none !important;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll] {
  min-height: 100vh;
} */

/* HTML ELEMENTS */
img {
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

/* LINKS */
a {
  text-decoration: none;
  color: #080808;
}

a:hover {
  text-decoration: underline;
  color: #080808;
}

/* FORM ELEMENTS */
input[type="text"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="email"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="search"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="password"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="number"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="date"] {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed;
  border-radius: 50%;
}

input[type="radio"]:checked {
  border: 6px solid #080808;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed;
}

input[type="checkbox"]:checked {
  border: 4px solid #ededed;
  background: #080808;
}

textarea {
  width: 520px;
  max-width: 100%;
  height: 140px;
  padding: 30px;
  border: 1px solid #cecece;
}

select {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 30px) 34px, calc(100% - 25px) 34px,
    calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, gray 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 25px) 34px, calc(100% - 30px) 34px,
    calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat;
  border-color: gray;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

input[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #080808;
  background: #94ffc4;
  border: none;
  padding: 0 50px;
}

button[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #080808;
  background: #94ffc4;
  border: none;
  padding: 0 50px;
}
button[type="submit"] i {
  display: inline-block;
  margin-right: 8px;
  font-size: 18px;
  transform: translateY(2px);
}

/* SPACING */
.no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}

.no-bottom-spacing {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.spacing-400 {
  height: 400px;
}

.spacing-200 {
  height: 200px;
}

.spacing-100 {
  height: 100px;
}

.spacing-50 {
  height: 50px;
}

/* ACCORDION */
.accordion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.accordion dt {
  width: 100%;
  display: block;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}
.accordion dt a {
  display: flex;
  align-items: center;
  position: relative;
}
.accordion dt a:before {
  content: "\f078";
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background: #94ffc4;
  border-radius: 50%;
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
}
.accordion dt a:hover {
  text-decoration: none;
}
.accordion dd {
  width: 100%;
  display: block;
  padding-top: 15px;
  margin-bottom: 30px;
}

/* HAMBURGER */
.hamburger path {
  -webkit-transform: translate3d(0, 0, 0);
  fill: transparent;
  stroke-width: 2px;
  stroke: #000;
  stroke-linecap: round;
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.hamburger path.line-center {
  padding: 10px 0;
}
.hamburger.active .line-center {
  -moz-transform: translateX(-50px);
  -webkit-transform: translateX(-50px);
  -o-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
}
.hamburger.active .line-top {
  -moz-transform: rotate(45deg) translateX(6px) translateY(-9px);
  -webkit-transform: rotate(45deg) translateX(6px) translateY(-9px);
  -o-transform: rotate(45deg) translateX(6px) translateY(-9px);
  -ms-transform: rotate(45deg) translateX(6px) translateY(-9px);
  transform: rotate(45deg) translateX(6px) translateY(-9px);
}
.hamburger.active .line-bottom {
  -moz-transform: rotate(-45deg) translateX(-15px) translateY(0px);
  -webkit-transform: rotate(-45deg) translateX(-15px) translateY(0px);
  -o-transform: rotate(-45deg) translateX(-15px) translateY(0px);
  -ms-transform: rotate(-45deg) translateX(-15px) translateY(0px);
  transform: rotate(-45deg) translateX(-15px) translateY(0px);
}

/* PRELOADER */
.preloader {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s;
  overflow: hidden;
}
.preloader .inner {
  display: inline-block;
  position: relative;
  z-index: 1;
  animation-name: preloader-inner;
  animation-duration: 0.8s;
  transition: all ease 0.8s;
}
.preloader .inner figure {
  width: 200px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-bottom: 0;
}
.preloader .inner figure img {
  height: 70px;
}
.preloader .inner small {
  width: 100%;
  display: block;
  color: #fff;
  font-family: "Cinzel", serif;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
}
.preloader .inner .progress-bar {
  background: none;
}
.preloader svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  fill: #080808;
}
.preloader svg path {
  animation-name: preloader-svg;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 0.7s;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

/* BEFORE PAGE LOADED */
.navbar {
  transform: translateY(-100%);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 0.2s;
}

.slider {
  transform: scale(1.4);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 0.25s;
  opacity: 0;
}

.page-header {
  transform: scale(1.4);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 0.25s;
  opacity: 0;
}

/* PAGE LOADED */
.page-loaded .preloader {
  top: 100%;
}

.page-loaded .preloader .inner {
  transform: translateY(50px);
  opacity: 0;
}

.page-loaded .preloader svg path {
  animation-play-state: running;
}

.page-loaded .navbar {
  transform: translateY(0);
}

.page-loaded .slider {
  transform: scale(1);
  opacity: 1;
}

.page-loaded .page-header {
  transform: scale(1);
  opacity: 1;
}

/* PAGE TRANSITION */
.page-transition {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: -100%;
  z-index: 99;
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.7s;
  overflow: hidden;
}
.page-transition.active {
  top: 0;
}
.page-transition.active svg path {
  animation-name: page-transition;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.page-transition svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  fill: #080808;
}

/* SEARCH BOX */
.search-box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;
  background: #fff;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.search-box.active {
  opacity: 1;
  visibility: visible;
}
.search-box .container {
  display: flex;
  flex-wrap: wrap;
}
.search-box .container .form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
}
.search-box .container .form h3 {
  width: 100%;
  display: block;
  font-family: "Cinzel", serif;
  margin-bottom: 30px;
  font-size: 30px;
}
.search-box .container .form input[type="search"] {
  width: 70%;
  border: none;
  border-bottom: 2px solid #eee;
  margin: 0 15%;
  margin-bottom: 20px;
  font-size: 19px;
}
.search-box .container .search-events {
  width: 100%;
  display: block;
  margin-top: 5vh;
}
.search-box .container .search-events ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.search-box .container .search-events ul li {
  flex: 1;
  margin: 0;
  padding: 0 5%;
  list-style: none;
  text-align: center;
}
.search-box .container .search-events ul li h5 {
  width: 100%;
  display: block;
  font-size: 20px;
  font-family: "Cinzel", serif;
  font-weight: 400;
}
.search-box .container .search-events ul li h5 a {
  display: inline;
  background-image: -moz-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 1px),
    #080808 2px
  );
  background-image: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 1px),
    #080808 2px
  );
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 1px),
    #080808 1px
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
}
.search-box .container .search-events ul li h5 a:hover {
  background-size: 100% 100%;
  text-decoration: none;
}
.search-box .container .search-events ul li small {
  width: 100%;
  display: block;
  opacity: 0.7;
}

/* SIDE WIDGET */
.side-widget {
  width: 540px;
  max-width: 80vw;
  height: 100vh;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 10;
  padding: 30px 50px;
  padding-right: 110px;
  color: #fff;
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  overflow: auto;
}
.side-widget.active {
  left: 0;
}
.side-widget.active .logo {
  opacity: 1;
  transform: translateX(0);
}
.side-widget.active .inner {
  opacity: 1;
  transform: translateX(0);
}
.side-widget.active .display-mobile {
  opacity: 1;
  transform: translateX(0);
}
.side-widget.active svg path {
  animation-name: side-widget-in;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}
.side-widget svg {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  fill: #080808;
}
.side-widget svg path {
  animation-name: side-widget-out;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}
.side-widget .logo {
  width: 80%;
  display: block;
  margin-bottom: 20px;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transform: translateX(-100px);
}
.side-widget .logo img {
  height: 62px;
}
.side-widget .inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transform: translateX(-100px);
}
.side-widget .inner .widget {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.side-widget .inner .widget:last-child {
  margin-bottom: 0;
}
.side-widget .inner .widget p:last-child {
  margin-bottom: 0;
  font-weight: 400;
  opacity: 0.8;
}
.side-widget .inner .widget-title {
  width: 100%;
  display: block;
  font-size: 26px;
  font-family: "Cinzel", serif;
  margin-bottom: 15px;
  color: #94ffc4;
}
.side-widget .display-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transform: translateX(-100px);
  display: none;
}
.side-widget .display-mobile .custom-menu {
  width: 100%;
  display: block;
  margin-top: auto;
}
.side-widget .display-mobile .custom-menu ul {
  width: 100%;
  margin: 0;
}
.side-widget .display-mobile .custom-menu ul li {
  width: 100%;
  font-family: "Cinzel", serif;
  font-size: 20px;
}
.side-widget .display-mobile .custom-menu ul li a {
  display: inline-block;
  color: #fff;
  margin: 5px 0;
}
.side-widget .display-mobile .custom-menu ul li a:hover {
  text-decoration: none;
  color: #94ffc4;
}
.side-widget .display-mobile .site-menu {
  width: 100%;
  display: block;
  margin-bottom: auto;
  margin-top: 10px;
}
.side-widget .display-mobile .site-menu ul {
  width: 100%;
  margin: 0;
}
.side-widget .display-mobile .site-menu ul li {
  width: 100%;
  font-family: "Cinzel", serif;
  font-size: 20px;
}
.side-widget .display-mobile .site-menu ul li a {
  display: inline-block;
  color: #fff;
  margin: 5px 0;
}
.side-widget .display-mobile .site-menu ul li a:hover {
  text-decoration: none;
  color: #94ffc4;
}

/* NAVBAR */
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 60px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}
.navbar.light {
  color: #fff;
}
.navbar.light .custom-menu ul li a {
  color: #fff;
}
.navbar.light .site-menu ul li a {
  color: #fff;
}
.navbar.light .hamburger-menu .hamburger path {
  stroke: #fff;
}
.navbar.light .navbar-button a {
  border: 3px solid transparent;
  line-height: 56px;
}
.navbar.light .navbar-button a:hover {
  border-color: #94ffc4;
  background: none;
  color: #fff;
}
.navbar .logo {
  display: inline-block;
}
.navbar .logo a {
  display: block;
}
.navbar .logo a img {
  height: 62px;
}
.navbar .custom-menu {
  display: inline-block;
  margin: 0 auto;
  font-weight: 600;
}
.navbar .custom-menu ul {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.navbar .custom-menu ul li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  list-style: none;
}
.navbar .custom-menu ul li a {
  display: inline-block;
  position: relative;
}
.navbar .custom-menu ul li a:hover {
  text-decoration: none;
}
.navbar .custom-menu ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.navbar .custom-menu ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.navbar .custom-menu ul li a:hover:before {
  width: 50%;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.navbar .custom-menu ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.navbar .site-menu {
  display: inline-block;
}
.navbar .site-menu ul {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}
.navbar .site-menu ul ul {
  position: absolute;
  top: 100%;
}
.navbar .site-menu ul li {
  display: inline-flex;
  margin: 0 15px;
  padding: 0;
  list-style: none;
  flex-direction: column;
  align-items: center;
}
.navbar .site-menu ul li a {
  display: inline-block;
  font-weight: 600;
  position: relative;
}
.navbar .site-menu ul li a:hover {
  text-decoration: none;
}
.navbar .site-menu ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.navbar .site-menu ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.navbar .site-menu ul li a:hover:before {
  width: 50%;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.navbar .site-menu ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.navbar .search-button {
  height: 30px;
  line-height: 34px;
  display: inline-block;
  margin-left: auto;
}
.navbar .search-button i {
  display: inline-block;
  font-size: 21px;
  cursor: pointer;
}
.navbar .hamburger-menu {
  margin-left: 30px;
  display: inline-block;
  cursor: pointer;
}
.navbar .navbar-button {
  margin-left: 30px;
}
.navbar .navbar-button a {
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding: 0 40px;
  background: #94ffc4;
  color: #080808;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navbar .navbar-button a:hover {
  text-decoration: none;
  background: #080808;
  color: #fff;
}

/* SLIDER */
.slider {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .slider {
    height: 100vh;
  }
}
.slider .container-fluid {
  width: calc(100% - 120px);
  margin: 0 60px;
}
.slider .kinetic-slider {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: #080808;
}
.slider .kinetic-slider canvas {
  width: 100% !important;
  height: auto !important;
  transform: none !important;
}
.slider .kinetic-texts {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  color: #fff;
}
.slider .kinetic-texts .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.slider .kinetic-texts .swiper-slide * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  opacity: 0;
}
.slider .kinetic-texts .swiper-slide.swiper-slide-active * {
  opacity: 1;
}
.slider .kinetic-texts .swiper-slide h1 {
  font-size: 126px;
  font-weight: 400;
  font-family: "Cinzel", serif;
  line-height: 1;
  margin-bottom: 30px;
}
.slider .kinetic-texts .swiper-slide p {
  font-size: 19px;
  margin: 0;
  opacity: 0.7;
  padding-left: 30px;
  border-left: 3px solid #fff;
  margin-left: 10px;
}
.slider .slider-images {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.slider .slider-images .swiper-slide {
  background-size: cover !important;
}
.slider .slider-images .swiper-slide .mobile-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover !important;
  display: none;
}
.slider .slider-texts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* padding-top: 32px; */
  position: relative;
  bottom: 10%;
}
.slider .slider-texts .svg-morph {
  position: absolute;
  top: calc(50% - 200px);
  left: 50%;
  transform: translateX(-140%);
}
.slider .slider-texts .svg-morph path {
  fill: #94ffc4;
  animation-name: svg-morph;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 8s;
  animation-fill-mode: both;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.slider .slider-texts .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.slider .slider-texts .swiper-slide h1 {
  font-size: 100px;
  font-weight: 400;
  font-family: "Cinzel", serif;
  line-height: 1;
  /* width: 52%; */
  margin-bottom: 30px;
}
.slider .slider-texts .swiper-slide p {
  font-size: 19px;
  margin: 0;
  opacity: 0.7;
  padding-left: 30px;
  border-left: 3px solid #080808;
  margin-left: 10px;
}
.slider .swiper-fraction,
.swiper-scrollbar-vertical .swiper-scrollbar {
  width: 200px;
  color: #fff;
}
/* .swiper-scrollbar-drag {
  width: 22px;
  display: inline-block;
  color: #080808;
  font-weight: 700;
} */
.slider .swiper-fraction span:before {
  content: "0";
}
.slider .swiper-fraction .swiper-pagination-current {
  margin-right: 122px;
}
.slider .swiper-pagination-progressbar {
  top: auto;
  bottom: 56px;
  left: 50px;
  width: 100px;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
}
.slider .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #080808;
}
.slider .slider-nav {
  display: flex;
  height: 70px;
  margin-top: -70px;
  position: relative;
  z-index: 3;
}
.slider .slider-nav .button-prev,
.swiper-button-prev {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background: #94ffc4;
  color: #080808;
  margin-left: auto;
  margin-right: 8px;
  position: relative;
  z-index: 3;
  cursor: pointer;
  transform: translateY(-140px);
  border-radius: 50%;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.slider .slider-nav .button-prev:hover,
.swiper-button-prev:hover {
  background: #080808;
  color: #94ffc4;
}
.slider .slider-nav .button-next,
.swiper-button-next {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background: #94ffc4;
  color: #080808;
  position: relative;
  z-index: 3;
  cursor: pointer;
  transform: translateY(-140px);
  border-radius: 50%;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.slider .slider-nav .button-next:hover,
.swiper-button-next:hover {
  background: #080808;
  color: #94ffc4;
}
.swiper-button-next:after {
  content: "\f077"; /* Unicode value of the chevron-up icon */
  font-family: "Font Awesome 5 Free"; /* Font family of the icon */
  font-weight: 900; /* Font weight of the icon */
}
.swiper-button-prev:after {
  content: "\f078"; /* Unicode value of the chevron-up icon */
  font-family: "Font Awesome 5 Free"; /* Font family of the icon */
  font-weight: 900; /* Font weight of the icon */
}
/* PLAY NOW */
.play-now {
  position: relative;
  width: 340px;
  height: 340px;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 4;
  overflow: hidden;
  margin-left: -100px;
}
.play-now .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 40px;
  border-color: transparent transparent transparent #080808;
  transform: translateX(-50%) translateY(-50%);
}
.play-now text {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 4px;
  fill: #080808;
}
.play-now svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 340px;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 10s;
  -moz-animation-duration: 10s;
  -ms-animation-duration: 10s;
  -o-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

/* PAGE HEADER */
.page-header {
  width: 100%;
  height: 540px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-size: cover !important;
}
.page-header .inner {
  width: 100%;
  display: block;
  padding: 0 60px;
  position: relative;
  /* position: absolute; */
  margin-top: 60px;
}
.page-header .inner h1 {
  font-size: 126px;
  font-weight: 400;
  font-family: "Cinzel", serif;
  line-height: 1;
  margin-bottom: 30px;
  position: relative;
}
.page-header .inner p {
  font-size: 19px;
  margin: 0;
  opacity: 0.7;
  padding-left: 30px;
  border-left: 3px solid #080808;
  margin-left: 10px;
  position: relative;
  padding-right: 50%;
}
.page-header .inner .svg-morph {
  position: absolute;
  top: calc(50% - 200px);
  left: 50%;
  z-index: 0;
  transform: translateX(-140%);
}
.page-header .inner .svg-morph path {
  fill: #94ffc4;
  animation-name: svg-morph;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-duration: 8s;
  animation-fill-mode: both;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

/* CONTENT SECTION */
.content-section {
  width: 100%;
  display: block;
  padding: 120px 0;
  position: relative;
  background-size: cover !important;
  background-position: center !important;
}
.content-section .container {
  position: relative;
  z-index: 1;
}
.content-section .container-fluid {
  position: relative;
  z-index: 1;
}

.bottom-white {
  position: relative;
}
.bottom-white:before {
  content: "";
  width: 100%;
  height: 140px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bottom-white:before .container {
  position: relative;
  z-index: 1;
}

/* VIDEO BG */
.video-bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.video-bg:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #080808;
  z-index: 1;
  opacity: 0.85;
}
.video-bg video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/* SECTION TITLE */
.section-title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.section-title figure {
  width: 100%;
  display: block;
  margin-bottom: 25px;
}
.section-title figure img {
  height: 70px;
}
.section-title h6 {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
  opacity: 0.5;
}
.section-title h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 92px;
  font-family: "Cinzel", serif;
  letter-spacing: -2px;
  line-height: 1;
}
.section-title h2 strong {
  font-weight: 600;
}

/* CUSTOM LINK */
.custom-link {
  display: inline-block;
  position: relative;
}
.custom-link:hover {
  text-decoration: none;
}
.custom-link:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.custom-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 4px;
  width: 0;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.custom-link:hover:before {
  width: 50%;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.custom-link:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}

/* CUSTOM BUTTON */
.col-12.text-center .custom-button {
  margin-top: 50px;
}

.custom-button {
  height: 70px;
  line-height: 70px;
  display: inline-block;
  background: #94ffc4;
  padding: 0 40px;
  color: #080808;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.custom-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid transparent;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.custom-button:hover {
  text-decoration: none;
  line-height: 80px;
  text-indent: 6px;
}
.custom-button:hover:before {
  top: 6px;
  left: 6px;
  border-color: #080808;
}

/* CIRCLE BUTTON */
.text-center .circle-button {
  margin: 0 auto;
}

.circle-button {
  width: 158px;
  height: 158px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #080808;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
}
.circle-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -5px;
  top: -5px;
  border: 1px solid #080808;
  border-radius: 50%;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.circle-button:hover {
  text-decoration: none;
}
.circle-button:hover:before {
  left: 0;
  top: 0;
  opacity: 0;
}
@keyframes scrollup {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* IMAGE BOX */
.image-box {
  width: 100%;
  display: block;
  margin: 0;
  position: relative;
  /* overflow: hidden; */
}
.image-box.is-reveal img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 1s;
}
.image-box img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}

/* SIDE ICON LIST */
.side-icon-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 80px;
}
.side-icon-list.right-side {
  padding-right: 0;
  padding-left: 80px;
}
.side-icon-list ul {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.side-icon-list ul li {
  width: 100%;
  display: block;
  margin-bottom: 50px;
  padding: 0;
  list-style: none;
}
.side-icon-list ul li:last-child {
  margin-bottom: 0;
}
.side-icon-list ul li figure {
  width: 80px;
  height: 100%;
  float: left;
  margin-bottom: 0;
}
.side-icon-list ul li figure img {
  height: 44px;
}
.side-icon-list ul li .content {
  width: calc(100% - 80px);
  display: inline-block;
  margin-top: 10px;
}
.side-icon-list ul li .content h5 {
  width: 100%;
  display: block;
  font-family: "Cinzel", serif;
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}
.side-icon-list ul li .content p {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  opacity: 0.7;
}
.side-icon-list ul li .content a {
  display: inline-block;
  text-decoration: underline;
}

/* IMAGE BOX CAROUSEL */
.image-box-carousel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.image-box-carousel figure {
  width: 100%;
  display: block;
  margin-bottom: 50px;
  position: relative;
}
.image-box-carousel figure:before {
  content: "";
  width: 1px;
  height: 64px;
  background: #080808;
  position: absolute;
  left: calc(50% - 0.5px);
  bottom: -32px;
  opacity: 0.8;
}
.image-box-carousel figure img {
  width: 100%;
}
.image-box-carousel .content-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 5px;
  text-align: center;
}
.image-box-carousel .content-box h5 {
  width: 100%;
  display: block;
  font-size: 30px;
  font-family: "Cinzel", serif;
}
.image-box-carousel .content-box .custom-link {
  color: #999;
  font-weight: 700;
}

/* SIDE CONTENT */
.side-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.side-content.left {
  padding-right: 20%;
}
.side-content small {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 20px;
  margin-bottom: 20px;
  white-space: nowrap;
  position: relative;
  z-index: 0;
  font-size: 14px;
  font-weight: 500;
  background: #94ffc4;
  border-radius: 40px;
}
.side-content h2 {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-family: "Cinzel", serif;
  font-size: 52px;
}
.side-content p {
  width: 100%;
  display: block;
  margin: 0;
  opacity: 0.7;
}
.side-content table {
  width: 100%;
  margin: 20px 0;
  opacity: 0.7;
}
.side-content table tr {
  padding: 5px 0;
}
.side-content table tr td:nth-child(2) {
  font-weight: 700;
}

/* SIDE IMAGE */
.side-image {
  width: 100%;
  display: block;
  margin: 0;
  overflow: hidden;
}
.side-image.left-half {
  width: 50vw;
  float: right;
}
.side-image.right-half {
  width: 50vw;
  float: left;
}
.side-image.is-reveal img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 1s;
}
.side-image img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}

/* SIDE LIST */
.side-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.side-list.left {
  padding-right: 20%;
}
.side-list.right {
  padding-left: 20%;
}
.side-list h2 {
  width: 100%;
  display: block;
  font-size: 52px;
  font-family: "Cinzel", serif;
  margin-bottom: 30px;
}
.side-list ul {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.side-list ul li {
  width: 100%;
  display: block;
  padding: 0;
  margin-bottom: 20px;
  list-style: none;
}
.side-list ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #080808;
  opacity: 0.5;
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.side-list ul li:last-child {
  margin-bottom: 0;
}

/* SIDE ARTS */
.side-arts {
  width: 50vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #080808;
  color: #fff;
  padding: 100px 80px;
}
.side-arts .titles {
  width: 100%;
  display: block;
  margin-bottom: 60px;
}
.side-arts .titles h6 {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
  opacity: 0.5;
}
.side-arts .titles h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 52px;
  font-family: "Cinzel", serif;
  letter-spacing: -2px;
  line-height: 1;
}
.side-arts ul {
  width: calc(100% + 30px);
  max-width: 480px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0;
}
.side-arts ul li {
  width: 50%;
  display: inline-block;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 15px;
  list-style: none;
}
.side-arts ul li img {
  width: 100%;
  display: block;
  border: 6px solid #fff;
}

/* COUNTER BOX */
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  padding: 0;
  padding-right: 7px;
}

.counter-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.counter-box .odometer {
  font-size: 82px;
  font-family: "Cinzel", serif;
  display: inline-block;
  line-height: 1;
  position: relative;
  padding: 0;
}
.counter-box .odometer:after {
  content: "";
  width: 70px;
  height: 70px;
  background: #94ffc4;
  border-radius: 50%;
  position: absolute;
  right: -50px;
  top: -20px;
  z-index: -1;
}
.counter-box .value {
  font-size: 82px;
  font-family: "Cinzel", serif;
  display: inline-block;
  line-height: 1;
  margin-left: 10px;
  position: relative;
  z-index: 1;
}
.counter-box p {
  width: 100%;
  display: block;
  margin: 0;
  text-align: center;
  font-weight: 700;
  opacity: 0.7;
}

/* IMAGE CONTENT BOX */
.col-lg-4:nth-child(1) .image-content-box {
  padding-right: 30px;
}

.col-lg-4:nth-child(2) .image-content-box {
  padding: 0 15px;
}

.col-lg-4:nth-child(3) .image-content-box {
  padding-left: 30px;
}

.image-content-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.image-content-box.is-reveal figure img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 1s;
}
.image-content-box figure {
  width: 100%;
  display: block;
  margin: 0;
  overflow: hidden;
}
.image-content-box figure img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.image-content-box .content-box {
  width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  margin-top: -30px;
  padding-top: 30px;
  padding-right: 30px;
}
.image-content-box .content-box h3 {
  width: 100%;
  display: block;
  font-size: 28px;
  font-family: "Cinzel", serif;
  margin-bottom: 10px;
}
.image-content-box .content-box p {
  width: 100%;
  display: block;
  margin: 0;
  opacity: 0.7;
}

/* TESTIMONIAL BOX */
.col-md-6:nth-child(2) .testimonial-box {
  margin-left: auto;
}

.testimonial-box {
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.testimonial-box .content-box {
  width: 100%;
  display: block;
  background: #fffbf7;
  position: relative;
  margin-bottom: 30px;
  padding: 50px;
  font-size: 19px;
}
.testimonial-box .content-box:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #fffbf7 transparent transparent transparent;
  position: absolute;
  left: 50px;
  bottom: -20px;
}
.testimonial-box h6 {
  width: 100%;
  display: block;
  margin: 0;
  padding-left: 50px;
}

/* COLLECTION BOX */
.col-lg-4:nth-child(3n + 1) .collection-box {
  padding-right: 30px;
}

.col-lg-4:nth-child(3n + 2) .collection-box {
  padding: 0 15px;
}

.col-lg-4:nth-child(3n + 3) .collection-box {
  padding-left: 30px;
}

.collection-box {
  width: 100%;
  display: block;
  margin: 15px 0;
}
.collection-box.is-reveal img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 1s;
}
.collection-box figure {
  width: 100%;
  display: block;
  margin-bottom: 35px;
  position: relative;
  overflow: hidden;
}
.collection-box figure img {
  width: 100%;
  /* opacity: 0; */
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.collection-box h4 {
  width: 100%;
  display: block;
  font-family: "Cinzel", serif;
  font-size: 30px;
  margin-bottom: 20px;
}
.collection-box h4 a {
  display: inline;
  background-image: -moz-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-image: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
}
.collection-box h4 a:hover {
  background-size: 100% 100%;
  text-decoration: none;
}
.collection-box p {
  width: 100%;
  display: block;
  margin: 0;
}

/* EXHIBITION BOX */
.col-lg-4:nth-child(3n + 1) .exhibition-box {
  padding-right: 30px;
}

.col-lg-4:nth-child(3n + 2) .exhibition-box {
  padding: 0 15px;
}

.col-lg-4:nth-child(3n + 3) .exhibition-box {
  padding-left: 30px;
}

.exhibition-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.exhibition-box.is-reveal {
  opacity: 1;
  animation: scrollup 1s;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.exhibition-box figure {
  width: 100%;
  display: block;
  margin-bottom: 35px;
  position: relative;
  overflow: hidden;
}
.exhibition-box.is-reveal figure img {
  opacity: 1;
}
.exhibition-box figure * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.exhibition-box figure .info {
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  position: absolute;
  right: 30px;
  bottom: 30px;
  text-align: center;
  border-radius: 40px;
}
.exhibition-box figure .info span {
  width: 100%;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 0;
  font-size: 14px;
  font-weight: 500;
  background: #94ffc4;
  border-radius: 40px;
}
.exhibition-box figure .info figure {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  background: #fff;
  border-radius: 40px;
}
.exhibition-box figure .info figure img {
  width: 17px;
  height: 17px;
}
.exhibition-box figure .info:hover {
  width: auto;
}
.exhibition-box figure .info:hover figure {
  opacity: 0;
}
.exhibition-box figure .info:hover span {
  max-width: 220px;
  padding: 0 20px;
}
.exhibition-box figure .img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.exhibition-box .content-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.exhibition-box .content-box h4 {
  width: 100%;
  display: block;
  font-family: "Cinzel", serif;
  font-size: 30px;
  margin-bottom: 20px;
}
.exhibition-box .content-box h4 a {
  display: inline;
  background-image: -moz-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-image: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) calc(99% - 2px),
    #080808 2px
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
}
.exhibition-box .content-box h4 a:hover {
  background-size: 100% 100%;
  text-decoration: none;
}
.exhibition-box .content-box p {
  width: 100%;
  display: block;
  margin: 0;
}

/* TEXT BOX */
.text-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.text-box h2 {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-family: "Cinzel", serif;
  font-size: 56px;
}
.text-box h3 {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-family: "Cinzel", serif;
  font-size: 46px;
}
.text-box p {
  width: 100%;
  display: block;
  opacity: 0.7;
}

/* TEXT CONTENT */
.col-lg-4:nth-child(3) .text-content {
  margin-top: 100px;
}

.text-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.text-content h6 {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-family: "Cinzel", serif;
  font-size: 26px;
  font-weight: 600;
}
.text-content p {
  width: 100%;
  display: block;
  padding-right: 35%;
  opacity: 0.7;
  position: relative;
  padding-left: 30px;
}
.text-content p:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #080808;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 6px;
}
.text-content p:last-child {
  margin-bottom: 0;
}

/* ART SLIDER */
.art-slider {
  width: 100%;
  float: left;
  position: relative;
  overflow: hidden;
}
.art-slider.is-reveal .art-slider-images {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 2s;
}
.art-slider .titles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.art-slider .titles h6 {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
  opacity: 0.5;
}
.art-slider .titles h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 62px;
  font-family: "Cinzel", serif;
  letter-spacing: -2px;
  line-height: 1;
}
.art-slider .art-slider-images {
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}

/* ART SLIDER CONTENT */
.art-slider-content {
  width: 100%;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.art-slider-content .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0.3;
}
.art-slider-content .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.art-slider-content .swiper-slide span {
  width: 70px;
  border-bottom: 3px solid #080808;
  margin-right: 40px;
  float: left;
  font-family: "Cinzel", serif;
  transform: rotate(-90deg);
  font-weight: 700;
}
.art-slider-content .swiper-slide h3 {
  display: block;
  font-family: "Cinzel", serif;
  font-weight: 700;
  margin: 0;
}

/* ART SLIDER IMAGES */
.art-slider-images {
  width: 50vw;
  float: left;
  position: relative;
  overflow: hidden;
}
.art-slider-images .swiper-slide {
  background: #080808;
}
.art-slider-images .swiper-slide img {
  width: 100%;
  float: left;
}

/* HORIZONTAL SCROLL */
.horizontal-scroll {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.horizontal-scroll .scroll-inner {
  width: 200vw;
  display: flex;
  position: relative;
  z-index: 1;
}
.horizontal-scroll .scroll-inner .scroll-wrapper {
  width: 100vw;
  float: left;
}

/* IMAGE ICON BOX */
.image-icon-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 185px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.image-icon-box.is-reveal .content-image img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 2s;
}
.image-icon-box:hover .content-box .expand {
  max-height: 190px;
}
.image-icon-box:hover .content-box p {
  margin-bottom: 40px;
  margin-top: 20px;
}
.image-icon-box * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.image-icon-box .icon {
  width: 100px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #94ffc4;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 1;
}
.image-icon-box .icon img {
  height: 50px;
}
.image-icon-box .content-image {
  width: 100%;
  display: block;
  margin: 0;
  overflow: hidden;
}
.image-icon-box .content-image img {
  width: 100%;
  display: block;
  /* opacity: 0; */
  /* transform: scale(1.2); */
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.image-icon-box .content-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  padding: 50px 10%;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.image-icon-box .content-box .expand {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.image-icon-box .content-box b {
  width: 100%;
  display: block;
  font-weight: 700;
  margin-bottom: 20px;
}
.image-icon-box .content-box h4 {
  width: 100%;
  display: block;
  font-size: 34px;
  font-family: "Cinzel", serif;
  margin-bottom: 0;
}
.image-icon-box .content-box p {
  width: 100%;
  margin: 0;
}
.image-icon-box .content-box a {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 15px;
}
.image-icon-box .content-box a:hover {
  text-decoration: none;
}
.image-icon-box .content-box a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.image-icon-box .content-box a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 4px;
  width: 0;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.image-icon-box .content-box a:hover:before {
  width: 50%;
  background: #94ffc4;
  transition: width 0.25s ease;
}
.image-icon-box .content-box a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}

/* CTA BOX */
.cta-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  color: #fff;
  margin: 50px 0;
}
.cta-box.dark {
  color: #080808;
  margin: 0;
}
.cta-box.dark .custom-button:hover {
  background: #94ffc4;
  color: #080808;
}
.cta-box.dark .custom-button:hover:before {
  border-color: #080808;
}
.cta-box figure {
  width: 100%;
  display: block;
  margin-bottom: 25px;
}
.cta-box figure img {
  height: 70px;
}
.cta-box h6 {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
}
.cta-box h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 92px;
  font-family: "Cinzel", serif;
  letter-spacing: -2px;
}
.cta-box h2 strong {
  font-weight: 600;
}
.cta-box p {
  padding: 0 20%;
}
.cta-box .custom-button {
  margin: 0 auto;
  margin-top: 60px;
}
.cta-box .custom-button:hover {
  background: #080808;
  color: #fff;
}
.cta-box .custom-button:hover:before {
  border-color: #fff;
}

/* NOTE BOX */
.note-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #94ffc4;
  color: #080808;
  padding: 60px;
  margin: 50px 0;
}
.note-box h2 {
  width: 100%;
  display: block;
  font-size: 52px;
  font-family: "Cinzel", serif;
  margin-bottom: 20px;
  line-height: 1.2;
}
.note-box p {
  width: 100%;
  display: block;
  margin: 0;
  opacity: 0.7;
  font-size: 19px;
}

/* RECENT NEWS */
.col-12:nth-child(2) .recent-news {
  margin-bottom: 100px;
}

.col-12:nth-child(even) .recent-news figure {
  order: 1;
}

.col-12:nth-child(even) .recent-news .content-box {
  order: 2;
  margin-right: 0;
  margin-left: -15%;
  text-align: right;
}

.col-12:nth-child(even) .recent-news .content-box p {
  padding-left: 30%;
  padding-right: 0;
}

.recent-news {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 150px;
  padding: 0 5%;
}
.recent-news figure {
  width: 60%;
  display: inline-block;
  margin: 0;
  overflow: hidden;
}
.recent-news figure.is-reveal img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 2s;
}
.recent-news figure img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.recent-news .content-box {
  width: 55%;
  display: inline-block;
  margin-right: -15%;
  position: relative;
  z-index: 1;
}
.recent-news .content-box small {
  height: 22px;
  line-height: 22px;
  display: inline-block;
  background: #94ffc4;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
}
.recent-news .content-box h3 {
  width: 100%;
  display: block;
  font-size: 54px;
  font-weight: 400;
  font-family: "Cinzel", serif;
  margin-bottom: 30px;
  margin-top: 10px;
}
.recent-news .content-box p {
  width: 100%;
  display: block;
  padding-right: 30%;
  margin-bottom: 40px;
  opacity: 0.7;
}

/* NEWSLETTER BOX */
.newsletter-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #94ffc4;
  padding: 50px 0;
}
.newsletter-box .form {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 50px;
}
.newsletter-box .form .titles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.newsletter-box .form .titles h6 {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
}
.newsletter-box .form .titles h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 72px;
  font-family: "Cinzel", serif;
  letter-spacing: -2px;
  line-height: 1;
}
.newsletter-box .form small {
  width: 100%;
  display: block;
  margin-top: 15px;
}
.newsletter-box .form small a {
  display: inline-block;
  text-decoration: underline;
}
.newsletter-box .form .inner {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #fff;
  padding: 10px;
}
.newsletter-box .form input[type="email"] {
  width: 70%;
  flex: auto;
  border: none;
  padding: 0;
  padding-left: 20px;
}
.newsletter-box .form input[type="submit"] {
  width: 30%;
  background: #94ffc4;
  padding: 0;
}
.newsletter-box .newsletter-image {
  width: 35%;
  display: inline-block;
  margin: 0;
}
.newsletter-box .newsletter-image img {
  width: 100%;
}

/* CONTACT BOX */
.contact-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-right: 10%;
}
.contact-box:last-child {
  margin-bottom: 0;
}
.contact-box h6 {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  font-family: "Cinzel", serif;
  font-size: 24px;
  font-weight: 600;
}
.contact-box a {
  display: block;
  text-decoration: underline;
}
.contact-box a:hover {
  text-decoration: none;
}
.contact-box p {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  opacity: 0.7;
}
.contact-box p:last-child {
  margin-bottom: 0;
}

/* CONTACT FORM */
.contact-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
}
.contact-form .mb-3 {
  width: 100%;
  display: block;
}
.contact-form .mb-3 input[type="text"] {
  width: 100%;
}
.contact-form .mb-3 textarea {
  width: 100%;
}

/* GOOGLE MAPS */
.google-maps {
  width: 100%;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #94ffc4;
}
.google-maps iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  filter: grayscale(1);
}

/* BLOG POST */
.blog-post {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 120px;
}
.blog-post figure {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  overflow: hidden;
}
.blog-post figure.is-reveal img {
  opacity: 1;
  transform: scale(1);
  animation: scrollup 2s;
}
.blog-post figure img {
  width: 100%;
  opacity: 0;
  transform: scale(1.2);
  transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.blog-post .content-box {
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  line-height: 1.7;
}
.blog-post .content-box small {
  height: 22px;
  line-height: 22px;
  display: inline-block;
  background: #94ffc4;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
}
.blog-post .content-box h3 {
  width: 100%;
  display: block;
  font-size: 54px;
  font-weight: 400;
  font-family: "Cinzel", serif;
  margin-bottom: 30px;
  margin-top: 10px;
}
.blog-post .content-box blockquote {
  width: 100%;
  display: block;
  margin: 40px 0;
  font-family: "Cinzel", serif;
  font-size: 22px;
  padding-left: 40px;
  border-left: 4px solid #94ffc4;
}
.blog-post .content-box p {
  width: 100%;
  display: block;
  margin-bottom: 40px;
  opacity: 0.7;
}
.blog-post .content-box p:last-child {
  margin-bottom: 0;
}

/* SIDEBAR */
.sidebar {
  width: 100%;
  display: block;
  padding-left: 30px;
}
.sidebar .widget {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #f1f1f1;
  border-right: 4px solid #f1f1f1;
  border-bottom: 4px solid #f1f1f1;
  padding: 35px;
  margin-bottom: 35px;
  position: relative;
}
.sidebar .widget * {
  position: relative;
}
.sidebar .widget .widget-title {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 22px;
  color: #080808;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-family: "Cinzel", serif;
}
.sidebar .widget .widget-title:after {
  content: "";
  width: 50px;
  height: 4px;
  background: #080808;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.sidebar .widget .widget-title:before {
  content: "";
  width: 100%;
  height: 4px;
  background: #f1f1f1;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  mix-blend-mode: difference;
}
.sidebar .widget form {
  width: 100%;
  display: block;
}
.sidebar .widget form input[type="submit"] {
  margin-top: 10px;
  background: #080808;
  color: #fff;
}
.sidebar .widget .categories {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.sidebar .widget .categories li {
  width: 100%;
  display: block;
  margin: 4px 0;
  padding: 0;
  list-style: none;
}
.sidebar .widget .categories li a {
  color: #080808;
  font-size: 19px;
}
.sidebar .widget .widget-gallery {
  width: calc(100% + 4px);
  float: left;
  margin: 0 -2px;
  padding: 0;
}
.sidebar .widget .widget-gallery li {
  width: 50%;
  float: left;
  margin: 0;
  padding: 2px;
  list-style: none;
}

/* PAGINATION */
.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pagination .page-item {
  display: inline-block;
}
.pagination .page-item.active .page-link {
  background: #080808;
  border-color: transparent;
  color: #fff;
}
.pagination .page-item .page-link {
  height: 60px;
  line-height: 60px;
  border-radius: 0 !important;
  padding: 0 20px;
  color: #080808;
}
.pagination .page-item .page-link:hover {
  text-decoration: none;
}

/* FOOTER */
.footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 120px;
}
.footer .logo {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.footer .logo img {
  height: 64px;
}
.footer .app-logos {
  width: 100%;
  display: block;
  margin: 0;
}
.footer .app-logos img {
  width: auto;
  height: 32px;
}
.footer .widget-title {
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 20px;
  font-family: "Cinzel", serif;
  position: relative;
  padding-bottom: 15px;
}
.footer .widget-title:after {
  content: "";
  width: 70px;
  height: 2px;
  background: #080808;
  position: absolute;
  left: 40%;
  bottom: 0;
}
.footer .social-media {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.footer .social-media li {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}
.footer .social-media li a {
  display: inline-block;
}
.footer .social-media li a i {
  width: 20px;
  display: inline-block;
  font-size: 14px;
}
.footer .social-media li a:hover {
  text-decoration: underline;
}
.footer .footer-menu {
  width: 100%;
  display: block;
  margin: 0;
  padding-left: 20px;
}
.footer .footer-menu li {
  margin-bottom: 8px;
}
.footer .footer-menu li a {
  display: inline-block;
  font-size: 16px;
}
.footer .address {
  width: 100%;
  display: block;
  line-height: 1.8;
}
.footer .address strong {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.footer .footer-bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 50px 0;
  border-top: double 10px #f7f7f7;
  margin-top: 60px;
  font-size: 14px;
}
.footer .footer-bottom .container {
  display: flex;
  flex-wrap: wrap;
}
.footer .footer-bottom .copyright {
  display: inline-block;
}
.footer .footer-bottom .creation {
  margin-left: auto;
  display: inline-block;
}
.footer .footer-bottom .creation a {
  display: inline-block;
  text-decoration: underline;
}
.footer .footer-bottom .creation a:hover {
  text-decoration: none;
}

/* RESPONSIVE MEDIUM  */
@media only screen and (max-width: 1199px),
  only screen and (max-device-width: 1199px) {
  .search-box .container .search-events ul li {
    padding: 0 2%;
  }

  .slider .slider-texts .swiper-slide h1 {
    font-size: 92px;
  }

  .navbar .site-menu ul li {
    margin: 0 10px;
  }

  .navbar .navbar-button {
    display: none;
  }

  .play-now {
    left: 60%;
  }

  .page-header .inner h1 {
    font-size: 92px;
  }

  .page-header .inner p {
    padding-right: 30%;
  }

  .section-title h2 {
    font-size: 72px;
  }

  .side-icon-list ul li {
    margin-bottom: 30px;
  }

  .side-icon-list.right-side {
    padding-left: 30px;
  }

  .exhibition-box .content-box h4 {
    font-size: 25px;
  }

  .exhibition-box {
    padding: 0 !important;
  }

  .collection-box {
    padding: 0 !important;
  }

  .collection-box h4 {
    font-size: 25px;
  }

  .image-content-box {
    padding: 0 !important;
  }

  .counter-box .odometer {
    font-size: 56px;
  }

  .counter-box .value {
    font-size: 56px;
  }

  .testimonial-box {
    max-width: 100%;
  }

  .text-content p {
    padding-right: 10%;
  }

  .image-icon-box .content-box h4 {
    font-size: 26px;
  }

  .image-icon-box:hover .content-box .expand {
    max-height: 400px;
  }

  .side-list.right {
    padding-left: 30px;
  }

  .side-content h2 {
    font-size: 46px;
  }

  .side-content.left {
    padding-right: 30px;
  }

  .art-slider-content {
    height: 300px;
  }

  .art-slider .titles h2 {
    font-size: 46px;
  }

  .cta-box h2 {
    font-size: 72px;
  }

  .contact-form {
    padding: 0;
  }

  .recent-news .content-box h3 {
    font-size: 38px;
  }

  .newsletter-box .form {
    width: 100%;
    padding: 0;
  }

  .newsletter-box .newsletter-image {
    display: none;
  }
}
/* RESPONSIVE TABLET  */
@media only screen and (max-width: 991px),
  only screen and (max-device-width: 991px) {
  input {
    border-radius: 0 !important;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="search"] {
    -webkit-appearance: none;
    appearance: none;
  }

  textarea {
    border-radius: 0 !important;
    -webkit-appearance: none;
    appearance: none;
  }

  .side-widget.active .display-mobile {
    display: flex;
    margin-top: -50px;
  }

  .side-widget.active .inner {
    display: none;
  }

  .side-widget {
    width: 440px;
  }

  .search-box .container .search-events ul {
    display: none;
  }

  .navbar .custom-menu {
    display: none;
  }

  .navbar .site-menu {
    display: none;
  }

  .navbar .navbar-button {
    display: inline-block;
  }

  .slider .slider-texts .swiper-slide h1 {
    font-size: 72px;
  }

  .slider .slider-texts .swiper-slide p {
    opacity: 1;
  }

  .slider .slider-images .swiper-slide .mobile-slide {
    display: block;
  }

  .play-now {
    display: none;
  }

  .section-title h2 {
    font-size: 48px;
  }

  .page-header .inner p {
    padding-right: 10%;
    padding-left: 0;
    border-left: none;
    margin-left: 0;
  }

  .side-icon-list {
    padding-left: 0;
  }

  .side-icon-list ul li .content p br {
    display: none;
  }

  .sidebar {
    padding-left: 0;
    margin-top: 60px;
  }

  .col-lg-4:nth-child(1) .exhibition-box {
    padding-right: 0;
  }

  .col-lg-4:nth-child(2) .exhibition-box {
    padding: 0;
  }

  .col-lg-4:nth-child(3) .exhibition-box {
    padding-left: 0;
    margin: 50px 0;
  }

  .col-lg-3 .circle-button {
    display: none;
  }

  .col-lg-5 .side-icon-list.right-side {
    margin-top: 60px;
  }

  .cta-box h2 {
    font-size: 52px;
  }

  .exhibition-box {
    margin-top: 60px;
  }

  .side-image.right-half {
    width: 100%;
  }

  .side-image.left-half {
    width: 100%;
  }

  .image-content-box {
    margin-top: 60px;
  }

  .side-arts {
    width: 100%;
  }

  .counter-box {
    margin: 15px 0;
  }

  .art-slider {
    margin-top: -80px;
  }

  .art-slider-content {
    height: 200px;
    margin-bottom: 80px;
  }

  .art-slider-images {
    width: 100%;
  }

  .image-icon-box {
    border-right: none;
  }

  .recent-news {
    padding: 0;
    margin-bottom: 80px;
  }

  .recent-news .content-box {
    width: 100%;
    margin-bottom: 0;
    order: 2;
  }

  .recent-news figure {
    width: 100%;
    order: 1;
    margin-bottom: 50px;
  }

  .contact-form {
    margin-top: 60px;
  }

  .col-12:nth-child(even) .recent-news .content-box {
    text-align: left;
    margin: 0;
  }

  .col-12:nth-child(even) .recent-news .content-box p {
    padding-left: 0;
  }

  .newsletter-box {
    padding: 50px 0;
  }

  .newsletter-box .form .titles h2 {
    font-size: 48px;
  }

  .footer .footer-menu {
    margin-bottom: 50px;
  }
}
/* RESPONSIVE MOBILE */
@media only screen and (max-width: 767px),
  only screen and (max-device-width: 767px) {
  .preloader {
    background: #080808;
  }

  .preloader svg {
    display: none;
  }

  .page-transition {
    background: #080808;
  }

  .page-transition svg {
    display: none;
  }

  .content-section {
    padding: 80px 0;
  }

  .side-widget {
    padding: 30px;
    padding-right: 0;
    width: 100%;
    max-width: 85vw;
  }

  .side-widget .logo img {
    height: 52px;
  }

  .search-box .container .form input[type="search"] {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }

  .navbar {
    padding: 20px 15px;
  }

  .navbar .navbar-button {
    display: none;
  }

  .navbar .logo a img {
    height: 48px;
  }

  .slider .container-fluid {
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 0;
  }

  .slider .slider-texts .swiper-slide h1 {
    font-size: 42px;
  }

  .slider .slider-texts .swiper-slide h1 br {
    display: none;
  }

  .slider .slider-texts .swiper-slide p {
    margin-left: 0;
    padding-left: 20px;
    font-size: 17px;
  }

  .slider .slider-texts .swiper-slide p br {
    display: none;
  }

  .slider .slider-nav.container-fluid {
    margin-top: -70px;
  }

  .slider .swiper-pagination-progressbar {
    display: none;
  }

  .slider .swiper-fraction {
    display: none;
  }

  .slider .slider-images .button-prev {
    margin-left: 0;
    width: 60px;
    height: 60px;
  }

  .slider .slider-images .button-next {
    width: 60px;
    height: 60px;
  }

  .page-header .inner {
    padding: 0 15px;
  }

  .page-header .inner h1 {
    font-size: 42px;
  }

  .page-header .inner p {
    font-size: 17px;
    padding-right: 0;
  }

  .section-title {
    margin-bottom: 60px;
  }

  .section-title h2 {
    font-size: 40px;
  }

  .section-title h2 br {
    display: none;
  }

  .side-content.left br {
    display: none;
  }

  .counter-box {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .side-content.left {
    padding-right: 0;
  }

  .side-content table {
    font-size: 14px;
  }

  .side-icon-list ul li figure {
    width: 100%;
    margin-bottom: 10px;
  }

  .side-icon-list ul li .content {
    width: 100%;
  }

  .side-icon-list ul li .content p br {
    display: none;
  }

  .col-lg-6 .side-list.right {
    padding-left: 0;
    margin-top: 60px;
  }

  .col-lg-3:last-child .counter-box {
    margin-bottom: 0;
  }

  .col-lg-6 .side-icon-list {
    margin-bottom: 50px;
    padding-right: 0;
  }

  .collection-box figure {
    margin-bottom: 15px;
  }

  .col-lg-4:nth-child(1) .image-content-box {
    margin-top: 0;
  }

  .blog-post .content-box h3 {
    font-size: 32px;
  }

  .note-box {
    padding: 30px;
  }

  .note-box h2 {
    font-size: 32px;
  }

  .text-box p {
    padding-right: 0;
  }

  .art-slider {
    margin-top: 0;
  }

  .art-slider-content {
    display: none;
  }

  .cta-box h2 {
    font-size: 42px;
  }

  .side-icon-list.right-side {
    padding: 0;
  }

  .exhibition-box {
    margin-top: 60px;
  }

  .col-12.text-center .custom-button {
    margin-top: 0;
  }

  .col-lg-4:nth-child(1) .exhibition-box {
    margin-top: 0;
  }

  .col-lg-4:nth-child(3) .text-box {
    margin: 50px 0;
  }

  .col-lg-4:last-child .image-icon-box .content-box {
    padding-bottom: 0;
  }

  .col-lg-4:nth-child(3) .text-content {
    margin: 30px 0;
  }

  .col-lg-6 .side-icon-list.left-side {
    padding-top: 60px;
    padding-right: 0;
  }

  .side-arts {
    padding: 80px 30px;
  }

  .testimonial-box {
    margin-bottom: 60px;
  }

  .testimonial-box .content-box {
    padding: 30px;
  }

  .col-md-6:last-child .testimonial-box {
    margin-bottom: 0;
  }

  .image-content-box .content-box {
    width: 100%;
    margin-top: 0;
  }

  .text-content {
    text-align: center;
  }

  .text-content p {
    padding: 0 5%;
  }

  .text-content p:before {
    display: none;
  }

  .image-icon-box {
    padding-bottom: 100px;
  }

  .image-icon-box .content-box {
    padding: 30px 0;
  }

  .image-icon-box .icon {
    width: 60px;
    height: 60px;
  }

  .image-icon-box .icon img {
    height: 36px;
  }

  .recent-news figure {
    margin-bottom: 20px;
  }

  .recent-news .content-box h3 {
    font-size: 27px;
  }

  .recent-news .content-box p {
    padding: 0;
  }

  .text-box h2 {
    font-size: 42px;
  }

  .col-md-2 img {
    margin-bottom: 30px;
  }

  .col-12:nth-child(2) .recent-news {
    margin-bottom: 50px;
  }

  .newsletter-box {
    padding: 50px 0;
  }

  .newsletter-box .form .titles h2 {
    font-size: 34px;
  }

  .newsletter-box .form .inner {
    width: 100%;
  }

  .newsletter-box .form input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
  }

  .newsletter-box .form input[type="submit"] {
    width: 100%;
  }

  .footer {
    padding-top: 80px;
  }

  .footer .social-media {
    margin-bottom: 50px;
  }

  .footer .footer-bottom .creation {
    width: 100%;
  }
}
/* ANIMATIONS */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes svg-morph {
  0% {
    d: path(
      "m244.333332,38.437499c26.666664,-1.666666 125.666662,0.999995 195.333325,50.33333c69.666663,49.333335 -39.666664,141.666664 -70.666664,174.229164c-31,32.5625 -176.999993,107.437507 -234.999993,76.437507c-58,-31 73.666665,-107.000001 49,-190.4375c-24.666665,-83.437499 34.666668,-108.895835 61.333332,-110.562501z"
    );
  }
  100% {
    d: path(
      "m244.333332,38.437499c58.666669,50.333342 24.666647,101.000011 94.33331,150.333346c69.666663,49.333335 71.333353,156.666666 14.333349,175.229163c-57.000004,18.562498 -160.999991,6.437492 -218.999991,-24.562508c-58,-31 -109.333363,-174.000012 -70.000018,-236.437507c39.333345,-62.437496 121.666681,-114.895836 180.33335,-64.562494z"
    );
  }
}
@-webkit-keyframes preloader-svg {
  0% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"
    );
  }
  50% {
    d: path(
      "M0,230 C305.333333,100 625.333333,0 960,0 C1294.66667,0 1614.66667,100 1920,300 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,960 305.333333,1080 0,1080 L0,230 Z"
    );
  }
  100% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"
    );
  }
}
@-webkit-keyframes preloader-svg {
  0% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"
    );
  }
  50% {
    d: path(
      "M0,230 C305.333333,100 625.333333,0 960,0 C1294.66667,0 1614.66667,100 1920,300 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,960 305.333333,1080 0,1080 L0,230 Z"
    );
  }
  100% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"
    );
  }
}
@-webkit-keyframes preloader-inner {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes preloader-inner {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes page-transition {
  0% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,980 1294.66667,930 960,930 C625.333333,930 305.333333,980 0,1080 L0,0 Z"
    );
  }
  100% {
    d: path(
      "M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"
    );
  }
}
@-webkit-keyframes side-widget-in {
  0% {
    d: path(
      "M540,1080H0V0h540c-40.28,124.78-85.13,311.48-85,541C455.13,769.53,499.81,955.48,540,1080z"
    );
  }
  50% {
    d: path(
      "M540,1080H0V0h540c31.96,142.05,60.09,325.94,60,541C599.91,755.16,571.87,938.34,540,1080z"
    );
  }
  100% {
    d: path(
      "M540,1080H0V0h540c0,179.85,0,359.7,0,539.54C540,719.7,540,899.85,540,1080z"
    );
  }
}
@-webkit-keyframes side-widget-out {
  0% {
    d: path(
      "M540,1080H0V0h540c0,179.85,0,359.7,0,539.54C540,719.7,540,899.85,540,1080z"
    );
  }
  50% {
    d: path(
      "M540,1080H0V0h540c-40.28,124.78-85.13,311.48-85,541C455.13,769.53,499.81,955.48,540,1080z"
    );
  }
  100% {
    d: path(
      "M540,1080H0V0h540c31.96,142.05,60.09,325.94,60,541C599.91,755.16,571.87,938.34,540,1080z"
    );
  }
}
.about-item,
.about-inner,
.about-inner-img {
  height: 100%;
}
.about-inner {
  position: relative;
}
.about-inner-content {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-align: center;
  background-color: #000;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.about-inner-content a,
.about-inner-content h5 {
  color: #fff;
  text-align: center;
}
.about-inner:hover .about-inner-content {
  opacity: 1;
}
.mission {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pd-90 {
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .pd-90 {
    padding: 40px 20px;
  }
}
@media screen and (min-width: 1024px) {
  .pd-90 {
    padding: 90px;
  }
  .navbar .hamburger-menu {
    display: none;
  }
}
.pd-top-90 {
  padding-top: 90px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 768px) {
  .flex-reverse {
    flex-direction: column-reverse;
  }
  .flex-column-min {
    flex-direction: column;
  }
}
.text-align-center {
  text-align: center;
}
.aheto-timeline__events-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -o-transition: height 0.4s;
  transition: height 0.4s;
  margin: 30px 0;
}
.aheto-timeline__events-content > ol,
.aheto-timeline__events ol {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.aheto-timeline__events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.aheto-timeline__events-content li.selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.aheto-timeline__wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: all 2s;
}

.aheto-timeline__timeline {
  position: relative;
  height: 100px;
  width: 100%;
  margin: 0 auto;
}
.aheto-timeline__events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 10px;
  overflow: hidden;
}
.aheto-timeline__events {
  background: rgba(34, 34, 34, 0.3);
}

.aheto-timeline__events {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* background: rgba(var(--ca-white), 0.3); */
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.aheto-timeline__events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  text-decoration: none;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.aheto-timeline__events a::after {
  border: 2px solid #000;
  background-color: #000;
  content: "";
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  /* border: 2px solid var(--c-light); */
  /* background-color: var(--c-light); */
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
@media screen and (max-width: 1024px) {
  .aheto-timeline__wrap {
    flex-direction: column;
  }
}
.aheto-timeline__content {
  text-align: center;
}
.aheto-timeline__navigation {
  padding-left: 0;
  list-style: none;
}
.aheto-timeline__navigation a {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 49%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #000;
  font-size: 16px;
  text-decoration: none;
}
.aheto-timeline__navigation a.prev {
  width: 20px;
  left: -12px;
  text-align: center;
}
.aheto-timeline__navigation a.prev::before {
  content: "\3c";
}
.aheto-timeline__navigation a.next {
  right: -12px;
  width: 20px;
  text-align: center;
}
.aheto-timeline__navigation a.next::before {
  content: "\3e";
}
.aheto-timeline__navigation a.inactive {
  cursor: not-allowed;
}
.navbar .site-menu ul .collection-sub {
  display: none;
  flex-direction: column;
}
.element-hovered {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.fl-left.wd-50 {
  float: left;
  width: 50%;
  margin-right: 20px;
}
.fl-right.wd-50 {
  float: right;
  width: 50%;
  margin-left: 20px;
}
.backgroundImg {
  display: none;
}
.sliderss {
  height: 40vh;
}
@media screen and (max-width: 576px) {
  .backgroundImg {
    display: block;
    height: 40%;
  }
  .page-header {
    height: 300px;
  }
}
.grid-fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  justify-content: center;
  align-items: center;
}
._wTR3b {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
@media screen and (max-width: 575px) {
  .grid-fr {
    grid-template-columns: 1fr;
  }
}
/* @media screen and (min-width: 1024px) { */
.hover-height {
  position: relative;
  height: 100%;
}
/* } */
/* @media screen and (min-width: 1024px) {
  .hover-height {
    height: 466px;
  }
} */
.partners img {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .partners img {
    width: 80%;
  }
}
.scrollup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #000;
  background-color: #94ffc4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.scrollup:hover {
  cursor: pointer;
}
.image-popup {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 50;
}
.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 40px;
  color: #ccc;
  border: none;
  background-color: transparent;
}
.prev-button,
.next-button {
  font-size: 40px;
  color: #ccc;
  border: none;
  background-color: transparent;
  position: fixed;
}
.next-button {
  right: 50px;
}
.prev-button {
  left: 50px;
}
.image-popup img {
  transition: opacity 0.5s ease-in-out;
  width: 30%;
}
